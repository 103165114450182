<template>
    <section
        v-if="homes && homes.length"
        class="o-flexible-module"
        :class="[{ 'o-flexible-module--flush': componentData.settings.spacing == 'flush' }]">
        <div v-if="componentData.settings.show_component_title" class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">Sales Showcase</div>
                </div>
            </div>
        </div>

        <div
            class="c-sales-showcase js-sales-showcase"
            :class="[
                `t-${componentData.settings.theme}`,
                {
                    'has-no-padding': componentData.settings.remove_padding == true,
                },
            ]"
            :data-component-id="componentId">
            <div v-if="componentData.title || componentData.intro" class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <div class="c-sales-showcase__title-wrapper">
                            <template v-if="componentData.title">
                                <h2
                                    v-if="componentData.settings.heading_level == 'h2'"
                                    class="c-sales-showcase__title wow animate__animated animate__fadeIn"
                                    v-html="componentData.title"></h2>

                                <h3
                                    v-else-if="componentData.settings.heading_level == 'h3'"
                                    class="c-sales-showcase__title wow animate__animated animate__fadeIn"
                                    v-html="componentData.title"></h3>

                                <div
                                    v-else
                                    class="c-sales-showcase__title wow animate__animated animate__fadeIn"
                                    v-html="componentData.title"></div>
                            </template>

                            <div
                                v-if="componentData.intro"
                                class="c-sales-showcase__intro s-wp-cms wow animate__animated animate__fadeIn"
                                v-html="componentData.intro"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <div class="c-sales-showcase__homes s-swiper">
                            <swiper-container
                                ref="containerRef"
                                :init="false"
                                :navigation-next-el="`.js-sales-showcase[data-component-id=${componentId}] .js-swiper-button-next`"
                                :navigation-prev-el="`.js-sales-showcase[data-component-id=${componentId}] .js-swiper-button-prev`"
                                :pagination-el="`.js-sales-showcase[data-component-id=${componentId}] .js-swiper-pagination`">
                                <swiper-slide v-for="(home, index) in homes" :key="index">
                                    <div class="c-sales-showcase__item">
                                        <div
                                            class="c-home__banner"
                                            width="360" height="271"
                                            v-if="home?.property_label?.value"
                                            :class="home.property_label.type">
                                            {{ home.property_label.value }}
                                        </div>

                                        <div v-if="home?.image_gallery_small?.length" class="c-sales-showcase__item-media">
                                            <img
                                                width="360" height="271"
                                                :src="home.image_gallery_small[0]"
                                                loading="lazy"
                                                :alt="home.title.rendered ? home.title.rendered : ''"
                                                :title="home.title.rendered ? home.title.rendered : ''" />
                                        </div>

                                        <div v-else class="c-sales-showcase__item-media has-placeholder"></div>

                                        <div class="c-sales-showcase__item-meta">
                                            <div class="c-sales-showcase__item-price">
                                                <HomePrice :home="home" />
                                            </div>

                                            <div
                                                class="c-sales-showcase__item-title"
                                                v-if="home.title.rendered"
                                                v-html="home.title.rendered"></div>

                                            <div class="c-sales-showcase__item-plot" v-if="home?.park && home?.plot">
                                                {{ home?.park }} Plot {{ home?.plot }}
                                            </div>

                                            <ul v-if="home.key_features" class="c-sales-showcase__item-features">
                                                <li
                                                    v-for="(feature, index) in home.key_features"
                                                    :key="index"
                                                    class="c-sales-showcase__item-feature">
                                                    <span v-html="feature.icon.element"></span>
                                                    <span v-html="feature.name"></span>
                                                </li>
                                            </ul>

                                            <NuxtLink
                                                class="c-sales-showcase__item-link c-button--slimline"
                                                :to="`/homes-for-sale/${home.slug}`"
                                                v-if="home?.slug"
                                                title="View holiday home">
                                                <span>
                                                    <i class="fa-solid fa-chevron-right"></i> View holiday home
                                                </span>
                                            </NuxtLink>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper-container>

                            <div v-if="homes && homes.length > 1" class="swiper-pagination js-swiper-pagination"></div>

                            <div v-if="homes && homes.length > 2" class="swiper-navigation">
                                <button
                                    class="swiper-button-prev js-swiper-button-prev"
                                    aria-label="Previous slide"
                                    type="button">
                                    <i class="fa-sharp fa-chevron-left"></i>
                                </button>

                                <button
                                    class="swiper-button-next js-swiper-button-next"
                                    aria-label="Next slide"
                                    type="button">
                                    <i class="fa-sharp fa-chevron-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const props = defineProps({
        componentData: {
            type: Object,
            required: true,
        },
    });

    const config = useRuntimeConfig();
    const componentId = useId();
    const containerRef = ref(null);

    const swiper = useSwiper(containerRef, {
        autoHeight: true,
        draggable: true,
        breakpoints: {
            768: {
                pagination: {
                    enabled: false,
                },
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1024: {
                pagination: {
                    enabled: false,
                },
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
        navigation: {
            enabled: true,
        },
        pagination: {
            enabled: true,
        },
        slidesPerView: 1,
        spaceBetween: 10,
    });

    const homes = ref([]);

    if (!props.componentData.settings.disable_global_homes) {
        const globalData = ref(null);

        const getGlobalSalesShowcaseData = async () => {
            const { data } = await useFetch(`/api/wp/components/sales-showcase`);

            // Check if data is empty or undefined
            if (!data || !data.value || data.value.length === 0) {
                // console.log('No data found for the given route:', route.fullPath);

                throw createError({
                    statusCode: 404,
                    statusMessage: "Page Not Found",
                });
            }

            globalData.value = data.value;
        };

        await getGlobalSalesShowcaseData();
    }

    const homeIds = computed(() => {
        if (props?.componentData?.homes) {
            return props.componentData.homes.map((homes) => {
                return homes.ID;
            });
        }

        return [];
    });

    const getHomes = async () => {
        // console.log(homes);

        const { data } = await useFetch("/api/wp/getHomes", {
            query: {
                acf_format: "standard",
                include: homeIds,
                per_page: homeIds.length,
            },
        });

        if (data.value) {
            if (data.value?.data) {
                homes.value = data.value.data;
            }
        }
    };

    await getHomes();

    // console.log("Data: ", props.componentData);
    // console.log("Home IDs: ", homeIds);
    // console.log("Homes: ", homes.value);
</script>
